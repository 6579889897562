import * as React from "react";
import "../App.css";
import "./Goals.css";

function Goals() {
    return (
        <>
            <div className="goals-title-area">
                <h1 className="content-title">Bündnisziele</h1>
                <h2 className="content-sub-title">"Wage Mut" für Demokratie – Groß&nbsp;in&nbsp;Krams</h2>
                <p className="goals-last-change">Version 1 - 22. August 2020</p>
            </div>
            <ul className="goals-list">
                <li className="goals-list-entry content-box">
                    Anliegen des Bündnisses sind Offenheit, Ehrlichkeit, Toleranz und gegenseitiger Respekt.
                    Diesbezüglich berufen wir uns auf die europäische Menschenrechtskonvention, die unsere
                    Menschenrechte und Grundfreiheiten schützt.<br />
                    <br />
                    Wir richten uns gegen faschistische und nationalsozialistische Haltungen, und es ist uns wichtig,
                    dass Groß Krams keine Bühne für rechtsextremischtische Betätigungen ist.
                </li>
                <li className="goals-list-entry content-box">
                    Wir streben nach einem intakten, generationsübergreifenden Dorfleben unter Einbindung von
                    Feuerwehr, Tischtennisverein und Jugendclub (und möchten somit auch die Attraktivität des
                    Landkreises Ludwigslust-Parchim steigern). Geplant sind vielfältige gemeinschaftliche Aktivitäten,
                    um den öffentlichen Raum zu füllen, damit keine Spielräume für Rechtsextremismus entstehen. Alle
                    Entscheidungen werden basisdemokratisch getroffen.
                </li>
                <li className="goals-list-entry content-box">
                    Wir visieren eine Gemeindevertretung ohne demokratieablehnende Mitglieder an. Gleiches gilt für
                    das Bürgermeisteramt.
                </li>
                <li className="goals-list-entry content-box">
                    Unsere Grundlage ist die demokratische Grundordnung mit freiem Meinungsaustausch, d.h. wir
                    möchten unsere Meinung frei äußern können und Andere ebenso dazu ermutigen.<br />
                    <br />
                    „Wage Mut!“ steht für die Aufforderung an Alle, für die freiheitlich demokratischen Werte
                    aufzustehen und sich nicht von Provokation, Einschüchterung und Verängstigung seitens
                    rechtsradikaler Bürger unterdrücken zu lassen.
                </li>
                <li className="goals-list-entry content-box">
                    Jede/r, die/der sich mit den Zielen des Bündnisses identifizieren kann, ist willkommen - ob als
                    Neumitglied, Unterstützer, Freund oder Sympathisant, aktiv oder passiv.<br />
                    <br />
                    Auch Nachbargemeinden, Vereine, andere Bündnisse und Initiativen möchten wir im Austausch
                    über Gemeindegrenzen hinweg in unser Engagement einbinden, um ein starkes Netzwerk
                    aufzubauen und gemeinsam die Demokratie nachhaltig zu stärken.
                </li>
            </ul>
        </>
    )
}

export default Goals;