import './App.css';
import * as React from "react";

import {BrowserRouter, Route, Routes} from 'react-router-dom';

import Navbar from "./feature/core/Navbar";
import Home from "./pages/Home";
import Footer from "./feature/core/Footer";
import PrivacyBanner from "./feature/privacy/PrivacyBanner";
import Goals from "./pages/Goals";
import Imprint from "./pages/Imprint";
import Articles from "./pages/Articles";
import ScrollToTop from "./helper/ScrollToTop";
import Contact from "./pages/Contact";
import Literature from "./pages/Literature";
import Press from "./pages/Press";
import VideoArticle from "./pages/VideoArticle";
import Error404 from "./pages/error/Error404";
import Events from "./pages/Events";
import SchwerinAfDWahlauftaktVideoArticle from "./pages/static-article/SchwerinAfDWahlauftaktVideoArticle";
import EventDetails from "./pages/EventDetails";
import Advent2021Article from "./pages/static-article/Advent2021Article";
import HerzlichWillkommenArticle from "./pages/static-article/HerzlichWillkommenArticle";
import BundesinnenministeriumNancyFaeserArticle from "./pages/static-article/BundesinnenministeriumNancyFaeserArticle";
import Community from "./pages/Community";
import Stellingpreis2022Article from "./pages/static-article/Stellingpreis2022";
import Datenschutzerklaerung from "./pages/legal/Datenschutzerklaerung";
import {usePrivacyStore} from "./stores/PrivacyStores";
import PrivacySettings from "./pages/legal/PrivacySettings";
import AktivFuerDemokratieUndToleranz2022 from "./pages/static-article/AktivFuerDemokratieUndToleranz2022";
import ErichKaryPreis2024Article from "./pages/static-article/erich-kary-preis-2024-article";

function App() {
    const { showPrivacyBanner } = usePrivacyStore();

    return (
        <div className="App" data-testid="app">
            <div className="App-logo">
                <div id="LogoWorld" className="App-logo-part" />
                <div id="LogoRing" className="App-logo-part" />
                <div id="LogoLocationMarker" className="App-logo-part" />
                <div id="LogoTextTop" className="App-logo-part" />
                <div id="LogoTextBottom" className="App-logo-part" />
            </div>
            <div className="content">
                <BrowserRouter>
                    <ScrollToTop />
                    <Navbar />
                    <div className="content-area">
                        <Routes>
                            <Route path="/" element={<Home/>} />
                            <Route path="/literature" element={<Literature/>} />
                            <Route path="/press" element={<Press/>} />
                            <Route path="/contact" element={<Contact/>} />
                            <Route path="/goals" element={<Goals/>} />
                            <Route path="/imprint" element={<Imprint/>} />
                            <Route path="/articles" element={<Articles/>} />
                            <Route path="/video-article" element={<VideoArticle/>} />
                            <Route path="/events" element={<Events/>} />
                            <Route path="/event" element={<EventDetails/>} />
                            <Route path="/community" element={<Community/>} />
                            <Route path="/articles/static/video/SchwerinAfDWahlauftakt" element={<SchwerinAfDWahlauftaktVideoArticle/>} />
                            <Route path="/articles/static/Advent2021" element={<Advent2021Article/>} />
                            <Route path="/articles/static/HerzlichWillkommen" element={<HerzlichWillkommenArticle/>} />
                            <Route path="/articles/static/BundesinnenministeriumNancyFaeser" element={<BundesinnenministeriumNancyFaeserArticle/>} />
                            <Route path="/articles/static/Stellingpreis2022" element={<Stellingpreis2022Article/>} />
                            <Route path="/articles/static/AktivFuerDemokratieUndToleranz2022" element={<AktivFuerDemokratieUndToleranz2022/>} />
                            <Route path="/articles/static/ErichKaryPreis2024" element={<ErichKaryPreis2024Article/>} />
                            <Route path="/legal/Datenschutzerklaerung" element={<Datenschutzerklaerung/>} />
                            <Route path="/legal/PrivacySettings" element={<PrivacySettings/>} />
                            <Route path="/404" element={<Error404/>} />
                            <Route path='*' element={<Error404/>}  />
                        </Routes>
                    </div>
                    <Footer />
                    {showPrivacyBanner ? (<PrivacyBanner />) : null}
                </BrowserRouter>
            </div>
        </div>
    );
}

export default App;