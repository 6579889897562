import "../../App.css";
import "./ArticleItem.css";
import {EditableTextField} from "../core/controls/editable/EditableTextField";
import * as React from "react";

interface VideoArticleItemParams {
    title: string;
    video: string;
    date: string;
    author: string;
}

function VideoArticleItem(props: VideoArticleItemParams) {
    return (
        <>
            <div className="article-wrap content-box">
                <div className="article-title-wrap">
                    <div className="article-date-wrap">
                        <EditableTextField className="article-date" text={props.date} />
                    </div>
                    <EditableTextField className="article-title" text={props.title} />
                </div>
                <div className="article-video-wrap">
                    <video width="100%" height="338" controls controlsList="nodownload">
                        <source src={"https://wage-mut-buendnis.de/scd/video/" + props.video} type="video/mp4" />
                        Ihr Browser unterst&uuml;tzt die Video widergabe nicht.
                    </video>
                </div>
                <div className="article_title_image_source">
                    <p>{"Quelle: " + props.author}</p>
                </div>
            </div>
        </>
    )
}

export default VideoArticleItem;