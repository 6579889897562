import {DateTime} from "luxon";

export function parseDate(str : string) {
    const mdy : string[] = str.split('.');
    return new Date(+mdy[2], +mdy[1], +mdy[0]);
}

export function dateDiff(first: number, second: number) {
    return Math.round((second-first)/(1000*60*60*24));
}

/**
 * @param {DateTime} eventStart
 * @param {DateTime} eventEnd
 */
export function GetDateRangeStr(eventStart : DateTime, eventEnd: DateTime | null) {
    if (eventEnd && eventStart !== eventEnd) {
        if (eventStart.day === eventEnd.day && eventStart.month === eventEnd.month && eventStart.year === eventEnd.year) {
            return eventStart.toLocaleString(DateTime.DATETIME_MED) + " - " + eventEnd.toLocaleString(DateTime.TIME_SIMPLE);
        } else {
            return eventStart.toLocaleString(DateTime.DATETIME_MED) + " - " + eventEnd.toLocaleString(DateTime.DATETIME_MED);
        }
    }
    return eventStart.toLocaleString(DateTime.DATETIME_MED);
}

/**
 * @param {number} daysLeft
 */
export function GetTimeLeftStr(daysLeft: number) {
    if (daysLeft < 0) {
        return "Beendet"
    } else if (daysLeft === 0) {
        return "Heute";
    } else if (daysLeft === 1) {
        return "Morgen"
    } else {
        return "In " + daysLeft + " Tagen";
    }
}