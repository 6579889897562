import {useEffect, useState} from "react";
import * as React from "react";
import {PrivacyChoiceItem} from "./PrivacyChoiceItem";
import {usePrivacyBoxStore, usePrivacyStore} from "../../stores/PrivacyStores";
import {Button} from "../core/controls/Button";
import "./PrivacyChoiceBox.css";
import {Link} from "react-router-dom";
import {Rel, Target} from "../../helper/Constants";

interface PrivacyChoiceBoxProps {
    showYoutube: boolean;
    showTwitter: boolean;
    isRequired: boolean;
}
export const PrivacyChoiceBox = (props: PrivacyChoiceBoxProps) => {
    // global data state
    const { allowFunctionalCookies, allowSocialYoutube, allowSocialTwitter, disallowSocialTwitter, disallowSocialYoutube, disallowAll } = usePrivacyStore();

    // box data state
    const {boxFunctionalCookies, boxSocialTwitter, boxSocialYoutube, activateFunctionalCookies,
        activateSocialTwitter, deactivateSocialTwitter, activateSocialYoutube, deactivateSocialYoutube, deactivateAll} = usePrivacyBoxStore();

    const [isButtonEnabled, setButtonEnabled] = useState(!props.isRequired);

    const toggleFunctionalCookies = () => {
        const newValue = !boxFunctionalCookies;

        if (newValue) {
           activateFunctionalCookies();
        } else {
            deactivateAll();
        }
    };

    const toggleSocialYoutube = () => {
        if (boxSocialYoutube) {
            deactivateSocialYoutube();
        } else {
            activateSocialYoutube();
        }
    };

    const toggleSocialTwitter = ()=> {
        if (boxSocialTwitter) {
            deactivateSocialTwitter();
        } else {
            activateSocialTwitter();
        }
    };

    const applyChanges = () => {
        if (boxSocialYoutube) {
            allowSocialYoutube();
        } else {
            disallowSocialYoutube();
        }

        if (boxSocialTwitter) {
            allowSocialTwitter();
        } else {
            disallowSocialTwitter()
        }

        if (boxFunctionalCookies) {
            allowFunctionalCookies();
        } else {
            disallowAll();
        }
    };

    useEffect(() => {
        let allFine = true;

        if (props.isRequired) {
            if (!boxFunctionalCookies)
                allFine = false;

            if (props.showYoutube && !boxSocialYoutube)
                allFine = false;

            if (props.showTwitter && !boxSocialTwitter)
                allFine = false;
        }

        setButtonEnabled(allFine)
    }, [props.isRequired, setButtonEnabled, props.showYoutube, props.showTwitter, boxFunctionalCookies, boxSocialTwitter, boxSocialYoutube]);

    return (
        <div className="privacy-choice-box-container">
            { props.isRequired ? (
                <div className="privacy-choice-box-required-text">
                    Um diese Funktion zu nutzen, stellen Sie bitte sicher, dass die folgenden Datenschutzeinstellungen aktiviert sind:
                </div>
            ) : null}
            <PrivacyChoiceItem
                title="Funktionale Cookies"
                isEnabled={true}
                isToggled={boxFunctionalCookies}
                onToggle={toggleFunctionalCookies}
            >
                Funktionale Cookies sind kleine Datenelemente, die auf dem Gerät eines Nutzers gespeichert werden und dazu dienen,
                die Funktionalität und Leistung dieser Website zu verbessern.<br />
                <Link to={"/legal/Datenschutzerklaerung"} target={Target.BLANK}>Datenschutzerklärung & Cookie Richtlinien</Link>
            </PrivacyChoiceItem>
            { props.showYoutube ? (
                <PrivacyChoiceItem
                    title="Youtube Funktion"
                    isEnabled={boxFunctionalCookies}
                    isToggled={boxSocialYoutube}
                    onToggle={toggleSocialYoutube}
                >
                    Die Youtube-Funktion verwendet eigene Cookies und stützt sich auf externe Datenverarbeitung.<br />
                    <a href="https://www.youtube.com/static?gl=DE&template=terms&hl=de" target={Target.BLANK} rel={Rel.NOOPENER_NOREFERRER}>YouTube Nutzungsbedingungen</a><br />
                    <a href="https://policies.google.com/privacy?hl=de" target={Target.BLANK} rel={Rel.NOOPENER_NOREFERRER}>Google Datenschutzerklärung & Nutzungsbedingungen</a>
                </PrivacyChoiceItem>
            ) : null }
            { props.showTwitter ? (
                <PrivacyChoiceItem
                    title="Twitter Funktion"
                    isEnabled={boxFunctionalCookies}
                    isToggled={boxSocialTwitter}
                    onToggle={toggleSocialTwitter}
                >
                    Die Twitter-Funktion verwendet eigene Cookies und stützt sich auf externe Datenverarbeitung.<br />
                    <a href="https://twitter.com/de/tos" target={Target.BLANK} rel={Rel.NOOPENER_NOREFERRER}>Twitter Nutzungsbedingungen</a>
                </PrivacyChoiceItem>
            ) : null }
            <div className="privacy-choice-box-button-container">
                <Button buttonStyle="btn--primary" buttonSize="btn--medium" onClick={applyChanges} isEnabled={isButtonEnabled} type={undefined} >
                    { boxFunctionalCookies || boxSocialYoutube || boxSocialTwitter ? "Akzeptieren" : "Übernehmen"}
                </Button>
            </div>
        </div>
    )
}