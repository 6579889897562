import * as React from "react";
import {OptionToggle} from "../core/controls/OptionToggle";
import "./PrivacyChoiceItem.css";
import {PropsWithChildren} from "react";

interface PrivacyChoiceItemProps{
     title: string;
     isEnabled: boolean;
     isToggled: boolean;
     onToggle: () => void;
}
export const PrivacyChoiceItem = (props: PropsWithChildren<PrivacyChoiceItemProps>) => {
    return (
        <div className={props.isEnabled ? "privacy-choice-item-container privacy-choice-item-container-enabled" : "privacy-choice-item-container privacy-choice-item-container-disabled"}>
            <div className="privacy-choice-item-text-container">
                <h2 className="privacy-choice-item-title">{props.title}</h2>
                <div className="privacy-choice-item-children">
                    {props.children}
                </div>
            </div>
            <div className="privacy-choice-item-toggle-container">
                <OptionToggle isEnabled={props.isEnabled} isToggled={props.isToggled} onToggle={props.onToggle} />
            </div>
        </div>
    )
};
