import * as React from "react";
import "../../App.css";
import ArticleItem from "../../feature/article/ArticleItem";
import ArticleQuote from "../../feature/article/ArticleQuote";
import {Rel, Target} from "../../helper/Constants";

function AktivFuerDemokratieUndToleranz2022() {
    return (
        <>
            <ArticleItem
                title={"Preisträger \"Aktiv für Demokratie und Toleranz\" 2022"}
                text={""}
                author={""}
                date={"Montag, 16. Januar 2023"}
                image={null}
                imageAuthor={""}
            >
                Vielen Dank für die Auszeichnung durch das Bündnis für Demokratie und Toleranz,
                die unsere Bemühungen um die Förderung von Demokratie und Toleranz und die Bekämpfung des Extremismus würdigt.
                <br /><br />
                Es ist eine Ehre, für unsere Arbeit anerkannt zu werden, und eine Motivation für uns, unsere Mission fortzusetzen.
                Wir sind dankbar für die Unterstützung und Ermutigung, die wir von all unseren Unterstützern erhalten haben.
                Gemeinsam können wir die Welt zu einem besseren und integrativeren Ort für alle machen.
                <br />
                <ArticleQuote
                    text={"Die Bündnisgründung stieß auch medial auf große Resonanz. So wurde diese vom NDR für die Dokumentation " +
                        "\"Mein Nachbar ist Nazi, was tun?\" begleitet, in der die NPD-Vergangenheit von Richter und Böhm behandelt wird. " +
                        "Auch regionale sowie überregionale Zeitungen berichteten über die Gemeinderatswahl und die darauffolgenden Aktivitäten des Bündnisses in Groß Krams."}
                    author={"Bündnis für Demokratie und Toleranz – gegen Extremismus und Gewalt"}
                />

                Mehr Infos:<br />
                <a href={"https://www.buendnis-toleranz.de/arbeitsfelder/anlaufstelle/initiativen/initiativenlandkarte/177257/buendnis-fuer-demokratie"} target={Target.BLANK} rel={Rel.NOOPENER_NOREFERRER}>Bündnis für Demokratie und Toleranz – gegen Extremismus und Gewalt</a>
            </ArticleItem>
        </>
    )
}

export default AktivFuerDemokratieUndToleranz2022;