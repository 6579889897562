import * as React from "react";
import "../../App.css";
import ArticleItem from "../../feature/article/ArticleItem";
import TweetItem from "../../feature/social/twitter/TweetItem";

import img from "../../images/Faeser_Nancy_feb19.jpg";

function BundesinnenministeriumNancyFaeserArticle() {
    return (
        <>
            <ArticleItem
                title={"Bundesinnenministerium - Nancy Faeser"}
                text={""}
                author={""}
                date={"Donnerstag, 03. Februar 2022"}
                image={img}
                imageAuthor={"nancy-faeser.de - Angelika Aschenbach"}
            >
                <div className="article-text">
                    <h2>Bundesinnenministerium</h2>
                    Olaf Scholz hat am 06.12.2021 Nancy Faeser als Bundesinnenministerin nominiert.<br /><br />
                    Frau Faeser hat sich in Hessen primär mit der Aufarbeitung von Verbrechen auseinandergesetzt,
                    die aus der rechtsextremen Szene heraus begangen wurden.
                    <TweetItem id="1467834923880960000" />
                    <h3>Reaktionen</h3>
                    Das Feedback im Internet ist überwiegend sehr positiv.
                    <TweetItem id="1467783615308259332" />
                    <TweetItem id="1467790102399275009" />
                    <h3>Ankündigungen und Versprechen</h3>
                    <TweetItem id="1480478632170733570" />
                    <TweetItem id="1481330768228007942" />
                </div>
            </ArticleItem>
        </>
    )
}

export default BundesinnenministeriumNancyFaeserArticle;