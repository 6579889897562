import "./Editable.css";
import "../Button.css";
import * as React from "react";

interface EditableRichTextAreaProps {
    className: string;
    text: string;
}

export const EditableRichTextArea = (props: EditableRichTextAreaProps) => {
    const renderDisplayView = () => {
        return(
            <div className={props.className + " textArea-display"}>
                {props.text}
            </div>
        )
    }

    // return inEditMode ? renderEditView() : renderDisplayView();
    return renderDisplayView();
};