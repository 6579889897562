import * as React from "react";
import {PrivacyChoiceBox} from "../../feature/privacy/PrivacyChoiceBox";
function PrivacySettings() {
    return (
        <div className="content-container content-box">
            <h1 className="content-title">Cookie und Privatsphäre Einstellungen</h1>
            Willkommen auf unserer Datenschutz- und Cookie-Einstellungsseite!
            <br /><br />
            Wir sind bestrebt, Ihre Privatsphäre zu schützen und Ihnen
            Ihnen ein positives und sicheres Online-Erlebnis zu bieten. Auf dieser Seite finden Sie Informationen darüber,
            wie wir Ihre persönlichen Daten sammeln, verwenden und weitergeben,
            sowie über Ihre Rechte und Wahlmöglichkeiten in Bezug auf Ihre Privatsphäre.
            <br /><br />
            Sie können auch Ihre Cookie-Einstellungen verwalten und sich gegen bestimmte Arten der Nachverfolgung entscheiden.
            Bitte nehmen Sie sich einen Moment Zeit, um unsere Datenschutz- und Cookie-Richtlinien zu lesen und zu verstehen,
            wie wir mit Ihren Daten umgehen.
            <br /><br />
            Sollten Sie Fragen oder Bedenken haben, zögern Sie nicht, uns zu kontaktieren.
            <br /><br />
            <PrivacyChoiceBox
                showYoutube={true}
                showTwitter={true}
                isRequired={false}
            />
        </div>
    )
}

export default PrivacySettings;