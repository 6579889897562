import * as React from "react";
import "../../App.css";
import ArticleItem from "../../feature/article/ArticleItem";

import welcomeGk from "../../images/ui/groß_krams_small.jpg";

function HerzlichWillkommenArticle() {
    return (
        <>
            <ArticleItem
                title={"Herzlich willkommen!"}
                text={"Liebe Nachbarn und Besucher,\n\n"
                    + "die Welt ist im Wandel – und unser Bündnis ist dabei keine Ausnahme. Wir bilden uns regelmäßig "
                    + "fort in der Bemühung, ihnen den Zugriff auf unser Angebot über eine zeitgemäße Webpräsenz zu erleichtern.\n\n"
                    + "Daher freuen wir uns, ihnen heute unseren neuen Webauftritt präsentieren zu dürfen!\n\n"
                    + "Wir haben die Seite komplett neu entworfen, so dass sie Informationen nun übersichtlicher und schneller bereitstellt. Viel Spaß!"}
                author={""}
                date={"Freitag, 02. Juli 2021"}
                image={welcomeGk}
                imageAuthor={""}
            />
        </>
    )
}

export default HerzlichWillkommenArticle;