import * as React from "react";
import "../App.css";
import "./Press.css";

import mail from "../images/ui/mail.svg";

function Press() {
    return (
        <>
            <div className="press-container">
                <div>
                    <h1 className="content-title">Presse Kontakt</h1>
                </div>
                <div className="press-text-container content-box">
                    <p><b>Mail</b></p>
                    <p><a href="mailto:buendnis-wage-mut@t-online.de?subject=Presse:%20Kein%20Betreff">buendnis-wage-mut@t-online.de</a></p>
                    <img src={mail} alt="mail" height="180px" width="230px"/>
                </div>
            </div>
        </>
    )
}

export default Press;