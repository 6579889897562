import {create} from "zustand";
import Cookies from "js-cookie";

const trueValue = "true";

/* cookie names */
const functionalCookiesCookie = 'privacy_allow_functional_cookies';
const socialTwitterCookie = 'privacy_allow_social_twitter';
const socialYoutubeCookie = 'privacy_allow_social_youtube';

/* initial cookie state */
const functionalCookiesInitialValue = Cookies.get(functionalCookiesCookie) === trueValue;
const socialTwitterInitialValue = Cookies.get(socialTwitterCookie) === trueValue;
const socialYoutubeInitialValue = Cookies.get(socialYoutubeCookie) === trueValue;

const shouldShowPrivacyBanner = !functionalCookiesInitialValue;

interface PrivacyState {
    showPrivacyBanner: boolean,
    functionalCookies: boolean,
    socialTwitter: boolean,
    socialYoutube: boolean,
    allowFunctionalCookies: () => void;
    allowSocialTwitter: () => void;
    disallowSocialTwitter: () => void;
    allowSocialYoutube: () => void;
    disallowSocialYoutube: () => void;
    disallowAll: () => void;
}

interface PrivacyBoxState {
    boxFunctionalCookies: boolean,
    boxSocialTwitter: boolean,
    boxSocialYoutube: boolean,
    activateFunctionalCookies: () => void;
    deactivateFunctionalCookies: () => void;
    activateSocialTwitter: () => void;
    deactivateSocialTwitter: () => void;
    activateSocialYoutube: () => void;
    deactivateSocialYoutube: () => void;
    deactivateAll: () => void;
}

export const usePrivacyStore = create<PrivacyState>((set) => ({
    showPrivacyBanner: shouldShowPrivacyBanner,
    functionalCookies: functionalCookiesInitialValue,
    socialTwitter: socialTwitterInitialValue,
    socialYoutube: socialYoutubeInitialValue,
    allowFunctionalCookies: () => {
        set(() => ({showPrivacyBanner: false, functionalCookies: true}));
        Cookies.set(functionalCookiesCookie, trueValue, { secure: true, expires: 365 });
    },
    allowSocialTwitter: () => {
        set(() => ({socialTwitter: true}));
        Cookies.set(socialTwitterCookie, trueValue, { secure: true, expires: 365 });
    },
    disallowSocialTwitter: () => {
        set(() => ({socialTwitter: false}));
        Cookies.remove(socialTwitterCookie);
    },
    allowSocialYoutube: () => {
        set(() => ({socialYoutube: true}));
        Cookies.set(socialYoutubeCookie, trueValue, { secure: true, expires: 365 });
    },
    disallowSocialYoutube: () => {
        set(() => ({socialYoutube: false}));
        Cookies.remove(socialYoutubeCookie);
    },
    disallowAll: () => {
        set(() => ({
            showPrivacyBanner: false,
            functionalCookies: false,
            socialTwitter: false,
            socialYoutube: false
        }));
        Cookies.remove(functionalCookiesCookie);
        Cookies.remove(socialTwitterCookie);
        Cookies.remove(socialYoutubeCookie);
    },
}));

export const usePrivacyBoxStore = create<PrivacyBoxState>((set) => ({
    boxFunctionalCookies: functionalCookiesInitialValue,
    boxSocialTwitter: socialTwitterInitialValue,
    boxSocialYoutube: socialYoutubeInitialValue,
    activateFunctionalCookies: () => {
        set(() => ({boxFunctionalCookies: true}));
    },
    deactivateFunctionalCookies: () => {
        set(() => ({boxFunctionalCookies: false}));
    },
    activateSocialTwitter: () => {
        set(() => ({boxSocialTwitter: true}));
    },
    deactivateSocialTwitter: () => {
        set(() => ({boxSocialTwitter: false}));
    },
    activateSocialYoutube: () => {
        set(() => ({boxSocialYoutube: true}));
    },
    deactivateSocialYoutube: () => {
        set(() => ({boxSocialYoutube: false}));
    },
    deactivateAll: () => {
        set(() => ({
            boxFunctionalCookies: false,
            boxSocialTwitter: false,
            boxSocialYoutube: false
        }));
    }
}));