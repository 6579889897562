import * as React from "react";
import "../../App.css";
import ArticleItem from "../../feature/article/ArticleItem";

import aImage from "../../images/snow_image.png";

function Advent2021Article() {
    return (
        <>
            <ArticleItem
                title={"Frohe Adventszeit!"}
                text={"Liebe Nachbarn und Besucher,\n\n"
                    + "Einen stimmungsvollen Advent sowie ein paar stille Stunden abseits von Stress und Hektik in der Vorweihnachtszeit wünschen Euch das Wage Mut Team!"}
                author={""}
                date={"Mittwoch, 01. Dezember 2021"}
                image={aImage}
                imageAuthor={""}
            />
        </>
    )
}

export default Advent2021Article;