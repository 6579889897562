import * as React from "react";
import "../../App.css";
import ArticleItem from "../../feature/article/ArticleItem";
import ArticleQuote from "../../feature/article/ArticleQuote";
import karyPreisCard from "../../images/article/erich-kary-preis-card-2024.png";
import {Rel, Target} from "../../helper/Constants";

function ErichKaryPreis2024Article() {
    return (
        <>
            <ArticleItem
                title={"„Wage Mut“ gewinnt Erich Kary Preis"}
                text={""}
                author={""}
                date={"Samstag, 04. Mai 2024"}
                image={karyPreisCard}
                imageAuthor={null}
            >
                <br/>
                Unser Bündnis hat den Erich Kary Preis 2024 in der Kategorie "Vereine und Verbände aus dem Landkreis
                LUP" gewonnen;

                <ArticleQuote
                    text={"... für ihr Engagement für Weltoffenheit, Demokratie & Toleranz, Bildung & Vermittlung eines humanistischen Weltbildes."}
                    author={"Die Linke - Fraktion im Kreistag Ludwigslust-Parchim"}
                />
                <ArticleQuote
                    text={"Die Vernichtung des Nazismus mit seinen Wurzeln ist unsere Losung. Der Aufbau einer neuen Welt des Friedens und der Freiheit ist unser Ziel."}
                    author={"Schwur der Überlebenden des KZ Buchenwald"}
                />
                <h3>Hintergrund Infos:</h3>
                <ul>
                    <li>
                        <a href={"https://video01.uni-frankfurt.de/Mediasite/Play/23044d53591b469ab7236d7d0b8b5ed31d"}
                           target={Target.BLANK} rel={Rel.NOOPENER_NOREFERRER}>Video Interview Erich Kary
                            (uni-frankfurt)</a>
                    </li>
                    <li>
                        <a href={"https://www.buchenwald.de/"} target={Target.BLANK} rel={Rel.NOOPENER_NOREFERRER}>Gedenkstätte
                            Buchenwald</a>
                    </li>
                </ul>
            </ArticleItem>
        </>
    )
}

export default ErichKaryPreis2024Article;