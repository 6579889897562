import { useState } from "react";
import * as React from "react";
import "./Editable.css";
import "../Button.css";

interface EditableTextFieldProps {
    className: string;
    text: string;
}

export const EditableTextField = (props: EditableTextFieldProps) => {
    const [currentText, setCurrentText] = useState(props.text);
    const [inEditMode, SetEditMode] = useState(false);
    let changedValue = currentText;

    const changeEditMode = () => {
        changedValue = currentText;
        SetEditMode(!inEditMode);
    }

    const updateValue = () => {
        setCurrentText(changedValue);
        SetEditMode(false);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const renderEditView = () => {
        return(
            <div className={"editable-text-field " + props.className}>
                <input type="Text" defaultValue={currentText} onChange={evt => changedValue = evt.target.value} />
                <button className="btn btn--primary btn--small btn--ok" onClick={updateValue}>Übernehmen</button>
                <button className="btn btn--primary btn--small btn--cancel" onClick={changeEditMode}>X</button>
            </div>
        )
    }

    const renderDisplayView = () => {
        return(
            <div className={"editable-text-field " + props.className} onDoubleClick={changeEditMode}>
                {currentText}
            </div>
        )
    }

    // return inEditMode ? renderEditView() : renderDisplayView();
    return renderDisplayView();
};