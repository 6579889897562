import * as React from "react";
import "../../App.css";
import "./ArticleItem.css";
import {EditableTextField} from "../core/controls/editable/EditableTextField";
import {usePrivacyStore} from "../../stores/PrivacyStores";
import {PrivacyChoiceBox} from "../privacy/PrivacyChoiceBox";

interface VideoArticleItemParams {
    title: string;
    date: string | null;
    ytVideo: string;
}

function VideoArticleItem(props: VideoArticleItemParams) {
    const { functionalCookies, socialYoutube } = usePrivacyStore();

    return (
        <>
            <div className="article-wrap content-box">
                <div className="article-title-wrap">
                    <div className="article-date-wrap">
                        <EditableTextField className="article-date" text={props.date ?? ""} />
                    </div>
                    <EditableTextField className="article-title" text={props.title} />
                </div>
                <div className="article-video-wrap">
                    { functionalCookies && socialYoutube ? (
                        <iframe id="ytplayer" width="100%" height="338" title="youtube-video" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                src={"https://www.youtube-nocookie.com/embed/" + props.ytVideo + "?autoplay=1&origin=https://www.wage-mut-buendnis.de/"}
                                frameBorder="0"/>
                    ) : (
                        <div className="article_video_preview_wrap">
                            {/* TODO design pass ... <img className="article_video_image" src={"https://img.youtube.com/vi/" + props.ytVideo + "/0.jpg"} alt="Video Vorschau"/>*/}
                            <PrivacyChoiceBox isRequired={true} showYoutube={true} showTwitter={false}/>
                        </div>
                    )}
                </div>
                <div className="article_title_image_source">
                    <p>Quelle: Youtube</p>
                </div>
            </div>
        </>
    )
}

export default VideoArticleItem;