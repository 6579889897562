import * as React from "react";
import {Link} from "react-router-dom";

function Datenschutzerklaerung() {
    return (
        <div className="content-container content-box">
                <h1>Datenschutzerklärung</h1>
                <h3>Information über die Erhebung personenbezogener Daten und Kontaktdaten des Verantwortlichen</h3>
                Wir freuen uns, dass Sie unsere Website besuchen und bedanken uns für Ihr Interesse.
                Im Folgenden informieren wir Sie über den Umgang mit Ihren personenbezogenen Daten bei der Nutzung
                unserer Website.
                Personenbezogene Daten sind hierbei alle Daten, mit denen Sie persönlich identifiziert werden können.
                Verantwortlicher für die Datenverarbeitung auf dieser Website im Sinne der Datenschutz-Grundverordnung
                (DSGVO) ist Dieter Gnann, Teichstraße 6, 19230 Groß Krams Tel. 0177/3504343.
                Die verantwortliche Person für die Verarbeitung personenbezogener Daten ist die natürliche oder
                juristische Person,
                die allein oder gemeinsam mit anderen die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
                bestimmt.

                <h3>Datenerfassung beim Besuch unserer Website</h3>
                Bei der bloß informatorischen Nutzung unserer Website, also wenn Sie sich nicht registrieren oder uns
                anderweitig Informationen übermitteln,
                erheben wir nur solche Daten, die Ihr Browser an unseren Server übermittelt (sog. „Server-Logfiles“).
                Wenn Sie unsere Website aufrufen, erheben wir ggf. die folgenden Daten, die für uns technisch
                erforderlich sind, um Ihnen die Website anzuzeigen:
                <ul>
                        <li>Unsere besuchte Website</li>
                        <li>Datum und Uhrzeit zum Zeitpunkt des Zugriffes</li>
                        <li>Menge der gesendeten Daten in Byte</li>
                        <li>Quelle/Verweis, von welchem Sie auf die Seite gelangten</li>
                        <li>Verwendeter Browser</li>
                        <li>Verwendetes Betriebssystem</li>
                        <li>Verwendete IP-Adresse (ggf.: in anonymisierter Form)</li>
                </ul>
                Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an
                der Verbesserung der Stabilität und Funktionalität unserer Website.
                Eine Weitergabe oder anderweitige Verwendung der Daten findet nicht statt. Wir behalten uns allerdings
                vor,
                die Server-Logfiles nachträglich zu überprüfen, sollten konkrete Anhaltspunkte auf eine rechtswidrige
                Nutzung hinweisen.

                <h2>Cookies</h2>
                Wir verwenden Cookies auf unserer Website, um Ihre Nutzererfahrung zu verbessern und Inhalte und
                Anzeigen zu personalisieren.
                Durch die weitere Nutzung dieser Website erklären Sie sich mit der Verwendung von Cookies gemäß unserer
                Cookie-Richtlinie einverstanden.
                <br/><br/>
                <h3>Was sind Cookies?</h3>
                Cookies sind kleine Textdateien, die auf Ihrem Gerät gespeichert werden, wenn Sie eine Website besuchen.
                Sie helfen der Website, sich an Ihre Präferenzen und Aktionen zu erinnern und die Inhalte und Funktionen
                der Website auf Ihre Bedürfnisse abzustimmen.
                <br/><br/>
                Es gibt zwei Arten von Cookies: Sitzungscookies und dauerhafte Cookies.
                Sitzungs-Cookies sind temporär und werden gelöscht, wenn Sie Ihren Browser schließen.
                Dauerhafte Cookies werden für einen längeren Zeitraum auf Ihrem Gerät gespeichert und können dazu
                verwendet werden,
                Ihre Präferenzen und Einstellungen bei mehreren Besuchen auf der Website zu speichern.
                <br/><br/>
                <h3>Welche Arten von Cookies verwenden wir?</h3>
                Wir verwenden auf unserer Website verschiedene Arten von Cookies, darunter:
                <br/><br/>
                <b>Funktionale Cookies:</b> Diese Cookies sind für das ordnungsgemäße Funktionieren der Website
                erforderlich. Sie ermöglichen es uns,
                Ihre Präferenzen und Einstellungen zu speichern und Ihnen personalisierte Inhalte und Funktionen zur
                Verfügung zu stellen.
                {/*}
            <br /><br />
            <b>Analytische Cookies:</b> Diese Cookies werden verwendet, um Informationen darüber zu sammeln, wie Sie unsere Website nutzen, z. B. welche Seiten
            Sie besuchen und welche Links Sie anklicken. Wir verwenden diese Informationen, um die Website zu verbessern und die Bedürfnisse und Interessen
            unserer Nutzer besser zu verstehen.
            <br /><br />
            <b>Marketing-Cookies:</b> Diese Cookies werden verwendet, um Ihre Online-Aktivitäten zu verfolgen und Ihnen personalisierte Werbung auf anderen
            Websites zu zeigen. Wir verwenden diese Cookies, um Ihre Interessen und Vorlieben zu verstehen, damit wir Ihnen relevante Werbung zeigen können,
            die für Sie wahrscheinlich von Interesse ist.
            */}
                <br/><br/>
                <h3>Externe Funktionen und Cookies von Dritten</h3>
                Unsere Website kann externe Funktionen enthalten, wie Schaltflächen für soziale Medien und eingebettete
                Inhalte (z. B. YouTube-Videos, Twitter-Feeds).
                <br/><br/>
                Diese Funktionen können ihre eigenen Cookies setzen und Ihre Daten in Übereinstimmung mit ihren eigenen
                Datenschutzrichtlinien erfassen und verwenden.
                Wir empfehlen Ihnen, die Datenschutzrichtlinien aller externen Funktionen, die Sie auf unserer Website
                verwenden, zu lesen.
                <br/><br/>
                <h3>Wie können Sie Cookies kontrollieren?</h3>
                Die meisten Webbrowser ermöglichen es Ihnen, Cookies über Ihre Browsereinstellungen zu kontrollieren.
                Sie können wählen, ob Sie alle Cookies annehmen oder ablehnen oder nur bestimmte Arten von Cookies
                akzeptieren möchten.
                Sie können auch Cookies löschen, die bereits auf Ihrem Gerät gesetzt wurden.
                Bitte beachten Sie, dass bei der Deaktivierung von Cookies einige Funktionen unserer Website
                möglicherweise nicht ordnungsgemäß funktionieren
                und Sie auf bestimmte Bereiche oder Funktionen möglicherweise nicht zugreifen können.
                <br/><br/>
                Unter <Link to={"/legal/PrivacySettings"}>Cookies und Privatsphäre Einstellungen</Link> können Sie
                detaillierte Einstellungen für unsere Website vornehmen.
                <br/><br/>
                Weitere Informationen über Cookies und deren Verwaltung finden Sie unter <a
            href="https://www.allaboutcookies.org">www.allaboutcookies.org</a> .
                <br/><br/>
                Sollten Sie Fragen oder Bedenken bezüglich der Verwendung von Cookies haben, zögern Sie bitte nicht, uns
                zu kontaktieren.
                <br/><br/>
                <h3>Rechte des Betroffenen</h3>
                Das geltende Datenschutzrecht gewährt Ihnen gegenüber dem Verantwortlichen hinsichtlich der Verarbeitung
                Ihrer personenbezogenen Daten umfassende Betroffenenrechte
                (Auskunfts- und Interventionsrechte), über die wir Sie nachstehend informieren:
                <br/><br/>
                Auskunftsrecht gemäß Art. 15 DSGVO: Sie haben insbesondere ein Recht auf Auskunft über Ihre von uns
                verarbeiteten personenbezogenen Daten,
                die Verarbeitungszwecke, die Kategorien der verarbeiteten personenbezogenen Daten, die Empfänger oder
                Kategorien von Empfängern,
                gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer bzw. die Kriterien
                für die Festlegung der Speicherdauer,
                das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung, Widerspruch gegen
                die Verarbeitung,
                Beschwerde bei einer Aufsichtsbehörde, die Herkunft Ihrer Daten, wenn diese nicht durch uns bei Ihnen
                erhoben wurden,
                das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf.
                aussagekräftige Informationen über die involvierte Logik und die Sie betreffende Tragweite und die
                angestrebten Auswirkungen einer solchen Verarbeitung,
                sowie Ihr Recht auf Unterrichtung, welche Garantien gemäß Art. 46 DSGVO bei Weiterleitung Ihrer Daten in
                Drittländer bestehen;
                <br/><br/>
                Recht auf Berichtigung gemäß Art. 16 DSGVO: Sie haben ein Recht auf unverzügliche Berichtigung Sie
                betreffender unrichtiger Daten und/oder
                Vervollständigung Ihrer bei uns gespeicherten unvollständigen Daten;
                <br/><br/>
                Recht auf Löschung gemäß Art. 17 DSGVO: Sie haben das Recht, die Löschung Ihrer personenbezogenen Daten
                bei Vorliegen der Voraussetzungen des Art. 17 Abs. 1 DSGVO zu verlangen.
                Dieses Recht besteht jedoch insbesondere dann nicht, wenn die Verarbeitung zur Ausübung des Rechts auf
                freie Meinungsäußerung und Information,
                zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur
                Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;
                <br/><br/>
                Recht auf Einschränkung der Verarbeitung gemäß Art. 18 DSGVO: Sie haben das Recht,
                die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, solange die von Ihnen
                bestrittene Richtigkeit Ihrer Daten überprüft wird,
                wenn Sie eine Löschung Ihrer Daten wegen unzulässiger Datenverarbeitung ablehnen und stattdessen die
                Einschränkung der Verarbeitung Ihrer Daten verlangen,
                wenn Sie Ihre Daten zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen,
                nachdem wir diese Daten nach Zweckerreichung nicht mehr benötigen
                oder wenn Sie Widerspruch aus Gründen Ihrer besonderen Situation eingelegt haben, solange noch nicht
                feststeht, ob unsere berechtigten Gründe überwiegen;
                <br/><br/>
                Recht auf Unterrichtung gemäß Art. 19 DSGVO: Haben Sie das Recht auf Berichtigung, Löschung oder
                Einschränkung der Verarbeitung gegenüber dem
                Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empfängern, denen die Sie betreffenden
                personenbezogenen Daten offengelegt wurden,
                diese Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es sei denn,
                dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen
                Aufwand verbunden. Ihnen steht das Recht zu, über diese Empfänger unterrichtet zu werden.
                <br/><br/>
                Recht auf Datenübertragbarkeit gemäß Art. 20 DSGVO: Sie haben das Recht, Ihre personenbezogenen Daten,
                die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu
                erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen,
                soweit dies technisch machbar ist;
                <br/><br/>
                Recht auf Widerruf erteilter Einwilligungen gemäß Art. 7 Abs. 3 DSGVO: Sie haben das Recht,
                eine einmal erteilte Einwilligung in die Verarbeitung von Daten jederzeit mit Wirkung für die Zukunft zu
                widerrufen.
                Im Falle des Widerrufs werden wir die betroffenen Daten unverzüglich löschen,
                sofern eine weitere Verarbeitung nicht auf eine Rechtsgrundlage zur einwilligungslosen Verarbeitung
                gestützt werden kann.
                Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum
                Widerruf erfolgten Verarbeitung nicht berührt;
                <br/><br/>
                Recht auf Beschwerde gemäß Art. 77 DSGVO: Wenn Sie der Ansicht sind, dass die Verarbeitung der Sie
                betreffenden personenbezogenen Daten gegen die DSGVO verstößt,
                haben Sie - unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs -
                das Recht auf Beschwerde bei einer Aufsichtsbehörde,
                insbesondere in dem Mitgliedstaat Ihres Aufenthaltsortes, Ihres Arbeitsplatzes oder des Ortes des
                mutmaßlichen Verstoßes.

                <h3>WIDERSPRUCHSRECHT</h3>

                WENN WIR IM RAHMEN EINER INTERESSENABWÄGUNG IHRE PERSONENBEZOGENEN DATEN AUFGRUND UNSERES ÜBERWIEGENDEN
                BERECHTIGTEN INTERESSES VERARBEITEN,
                HABEN SIE DAS JEDERZEITIGE RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN
                DIESE VERARBEITUNG WIDERSPRUCH MIT WIRKUNG FÜR DIE ZUKUNFT EINZULEGEN.
                <br/><br/>
                MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE VERARBEITUNG DER BETROFFENEN DATEN.
                EINE WEITERVERARBEITUNG BLEIBT ABER VORBEHALTEN,
                WENN WIR ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN KÖNNEN, DIE IHRE INTERESSEN,
                GRUNDRECHTE UND GRUNDFREIHEITEN ÜBERWIEGEN, ODER WENN DIE VERARBEITUNG DER GELTENDMACHUNG,
                AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN DIENT.
                <br/><br/>
                WERDEN IHRE PERSONENBEZOGENEN DATEN VON UNS VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, HABEN SIE DAS
                RECHT,
                JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
                DERARTIGER WERBUNG EINZULEGEN.
                SIE KÖNNEN DEN WIDERSPRUCH WIE OBEN BESCHRIEBEN AUSÜBEN.
                <br/><br/>
                MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE VERARBEITUNG DER BETROFFENEN DATEN ZU
                DIREKTWERBEZWECKEN.

                <h3>Dauer der Speicherung personenbezogener Daten</h3>

                Die Dauer der Speicherung von personenbezogenen Daten bemisst sich anhand der jeweiligen
                Rechtsgrundlage,
                am Verarbeitungszweck und – sofern einschlägig – zusätzlich anhand der jeweiligen gesetzlichen
                Aufbewahrungsfrist
                (z.B. handels- und steuerrechtliche Aufbewahrungsfristen).
                <br/><br/>
                Bei der Verarbeitung von personenbezogenen Daten auf Grundlage einer ausdrücklichen Einwilligung gemäß
                Art. 6 Abs. 1 lit.
                a DSGVO werden diese Daten so lange gespeichert, bis der Betroffene seine Einwilligung widerruft.
                <br/><br/>
                Existieren gesetzliche Aufbewahrungsfristen für Daten, die im Rahmen rechtsgeschäftlicher bzw.
                rechtsgeschäftsähnlicher Verpflichtungen auf der Grundlage von Art. 6 Abs. 1 lit. b DSGVO verarbeitet
                werden,
                werden diese Daten nach Ablauf der Aufbewahrungsfristen routinemäßig gelöscht, sofern sie nicht mehr zur
                Vertragserfüllung oder Vertragsanbahnung
                erforderlich sind und/oder unsererseits kein berechtigtes Interesse an der Weiterspeicherung
                fortbesteht.
                <br/><br/>
                Bei der Verarbeitung von personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO werden
                diese Daten so lange gespeichert,
                bis der Betroffene sein Widerspruchsrecht nach Art. 21 Abs. 1 DSGVO ausübt, es sei denn, wir können
                zwingende schutzwürdige Gründe für die Verarbeitung nachweisen,
                die die Interessen, Rechte und Freiheiten der betroffenen Person überwiegen, oder die Verarbeitung dient
                der Geltendmachung,
                Ausübung oder Verteidigung von Rechtsansprüchen.
                <br/><br/>
                Bei der Verarbeitung von personenbezogenen Daten zum Zwecke der Direktwerbung auf Grundlage von Art. 6
                Abs. 1 lit. f DSGVO werden diese Daten so lange gespeichert,
                bis der Betroffene sein Widerspruchsrecht nach Art. 21 Abs. 2 DSGVO ausübt.
                <br/><br/>
                Sofern sich aus den sonstigen Informationen dieser Erklärung über spezifische Verarbeitungssituationen
                nichts anderes ergibt,
                werden gespeicherte personenbezogene Daten im Übrigen dann gelöscht, wenn sie für die Zwecke,
                für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig sind.<br/><br/>
                <p>(Stand: 15.01.2023)</p>
        </div>
    )
}

export default Datenschutzerklaerung;