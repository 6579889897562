import * as React from "react";
import "./LiteratureItem.css";
import {Rel, Target} from "../../helper/Constants";

import isbnImage from "../../images/ui/stripe_code.png";
import pagesImage from "../../images/ui/pages.png";

interface LiteratureItemParams {
    title: string;
    text?: string;
    src: string;
    image: string;
    isbn?: string;
    pages?: string;
}

function LiteratureItem(props: LiteratureItemParams) {
    return (
        <>
            <li className="literature_item content-box">
                <a className="literature_item_web_link" href={props.src} rel={Rel.NOOPENER_NOREFERRER} target={Target.BLANK}>
                    <img className="literature_item_image" src={props.image} alt="Book"/>
                </a>
                <div className="literature_item_right">
                    <div className="literature_item_title">
                        <h2>{props.title}</h2>
                    </div>
                    <div className="literature_item_text">
                        {props.text}
                    </div>
                    <div className="literature_item_info">
                        <div className="literature_item_info_entry literature_item_info_isbn">
                            <img src={isbnImage} alt="ISBN"/>ISBN: {props.isbn}
                        </div>
                        <div className="literature_item_info_entry literature_item_info_pages">
                            <img src={pagesImage} alt="pages"/>Seiten: {props.pages}
                        </div>
                    </div>
                </div>
            </li>
        </>
    )
}

export default LiteratureItem;