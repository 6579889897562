import * as React from "react";
import "../../App.css";
import ErrorArea from "../../feature/error/ErrorArea";

function Error404() {
    return (
        <>
            <ErrorArea code={404} text={"Wir können die von Ihnen gesuchte Seite nicht finden."} />
        </>
    )
}

export default Error404;