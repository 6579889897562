import * as React from "react";
import {PropsWithChildren} from "react";
import "../../App.css";
import "./ArticleItem.css";
import {EditableTextField} from "../core/controls/editable/EditableTextField";
import {EditableRichTextArea} from "../core/controls/editable/EditableRichTextArea";

interface ArticleItemParams {
    title: string;
    text: string;
    date: string;
    author: string;
    image: string | null;
    imageAuthor: string | null;
}

function ArticleItem(props: PropsWithChildren<ArticleItemParams>) {
    return (
        <>
            <div className="article-wrap content-box" >
                <div className="article-title-wrap">
                    <div className="article-date-wrap">
                        <EditableTextField className="article-date" text={props.date} />
                    </div>
                    <EditableTextField className="article-title" text={props.title} />
                    { props.author ? (
                        <div className="article-author-wrap">
                            Autor: <EditableTextField className="article-author" text={props.author} />
                        </div>
                    ) : (
                        <div className="article-author-wrap-empty"/>
                    )}
                </div>
                { props.image != null ? (
                    <img className="article_title_image" src={props.image} alt="Artikel Bild"/>
                ) : null}
                { props.imageAuthor ? (
                    <div className="article_title_image_source">
                        (Foto © <EditableTextField className="article-image-author" text={props.imageAuthor} />)
                    </div>
                ) : (
                    <div className="article_title_image_source-empty"/>
                )}
                <div>
                    {props.text !== "" ? <EditableRichTextArea className="article-text" text={props.text} /> : props.children }
                </div>
            </div>
        </>
    )
}

export default ArticleItem;