import * as React from "react";
import "./EventListItem.css";
import {Link} from "react-router-dom";
import {GetDateRangeStr, GetTimeLeftStr} from "../../helper/DateTimeTools";
import {EventItemParams, getEventDaysLeft, getEventEnd, getEventStart} from "./EventItem";
import {DateTime} from "luxon";



function EventListItem(props: EventItemParams) {
    const eventStart: DateTime = getEventStart(props);
    const eventEnd: DateTime | null = getEventEnd(props);
    const daysLeft: number = getEventDaysLeft(eventStart);

    return (
        <>
            <Link className="events-container content-box event_item_link" to={ "/event?id=" + props.id}>
                <div className="events-time-left">{GetTimeLeftStr(daysLeft)}</div>
                <div className="events-left">
                    <div className="events-date">{GetDateRangeStr(eventStart, eventEnd)}</div>
                    <div className="events-text">{props.title}</div>
                    <div className="events-location">{props.location}</div>
                </div>
            </Link>
        </>
    )
}

export default EventListItem;