import * as React from "react";
import "./ArticleImage.css";

interface ArticleImageParams {
    src: string;
    alt: string;
    author: string;
}

function ArticleImage(props: ArticleImageParams) {
    return (
        <div className="article-image-container">
            <img src={ props.src } alt={ props.alt } width={600} />
            {
                 (props.author) ? (
                     <div className="article-image-source">
                         (Foto © { props.author })
                     </div>
                 ) : null
            }
        </div>
    )
}

export default ArticleImage;