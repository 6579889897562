import * as React from "react";
import "../App.css";
import Cards from "../feature/cards/Cards";

function Articles() {
    return (
        <>
            <h1 className="content-title">Artikel</h1>
            <Cards/>
        </>
    )
}

export default Articles;