import "../App.css";
import "./Community.css";
import {Hashtag} from "../feature/social/hashtag/Hashtag";
import * as React from "react";


function Community() {
    return (
        <div className="content-container">
            <h1 className="content-title">Gemeinschaft</h1>
            <br /><br />
            <div className="content-box"><Hashtag meta={"gruppebeherzt"} instagram={true}/></div>
            <br /><br />
            <div className="content-box"><Hashtag meta={"unteilbarMV"} facebook={true} instagram={true} twitter={true}/></div>
        </div>
    )
}

export default Community;