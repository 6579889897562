import * as React from "react";
import "../App.css";
import VideoArticleItem from "../feature/article/VideoArticleItem";
import {GetQueryVariable} from "../helper/QueryTools"

function VideoArticle() {
    return (
        <>
            <VideoArticleItem
                title={decodeURIComponent(GetQueryVariable("title"))}
                ytVideo={GetQueryVariable("video")}
                date={null}
            />
        </>
    )
}

export default VideoArticle;