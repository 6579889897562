import * as React from "react";
import "./Button.css";
import {PropsWithChildren} from "react";

const STYLES: string[] = ["btn--primary", "btn--outline"];
const SIZES: string[] = ["btn--medium", "btn--large"];

interface ButtonProps {
     type?: "submit" | "reset" | "button" | undefined;
     onClick: () => void;
     isEnabled: boolean;
     buttonStyle: string;
     buttonSize: string;
}
export const Button = (props: PropsWithChildren<ButtonProps>) => {
    const checkButtonStyle = STYLES.includes(props.buttonStyle)
        ? props.buttonStyle
        : STYLES[0];

    const checkButtonSize = SIZES.includes(props.buttonSize) ? props.buttonSize : SIZES[0];

    const handleClick = () => {
        if (props.isEnabled)
            props.onClick();
    };

    return (
        <div className="btn-main">
            <button
                className={`btn ${checkButtonStyle} ${checkButtonSize} ${props.isEnabled ? "btn--enabled" : "btn--disabled"}`}
                onClick={handleClick}
                type={props.type}
            >
                {props.children}
            </button>
        </div>
    )
};