import * as React from "react";
import "../../App.css";
import ArticleItem from "../../feature/article/ArticleItem";
import ArticleQuote from "../../feature/article/ArticleQuote";
import ArticleImage from "../../feature/article/ArticleImage";


import stellingpreis1Image from "../../images/article/stellingpreis2022/stellingpreis_1.jpg";
import stellingpreis2Image from "../../images/article/stellingpreis2022/stellingpreis_2.jpg";

function Stellingpreis2022Article() {
    return (
        <>
            <ArticleItem
                title={"„Wage Mut“ gewinnt Stellingpreis 2022"}
                text={""}
                author={""}
                date={"Sonntag, 27. November 2022"}
                image={stellingpreis1Image}
                imageAuthor={"Julia Kleefeld"}
            >
                Wir freuen uns und fühlen uns geehrt, diese Auszeichnung zu erhalten, mit der die Bemühungen all derer gewürdigt werden,
                die sich für die Werte der Demokratie und des harmonischen Zusammenlebens einsetzen. Sie ist ein Zeugnis
                für die harte Arbeit und das Engagement unserer Gemeinschaft, und wir sind dankbar, dass wir diese Auszeichnung mit Ihnen allen teilen können.
                <br /> <br />
                Auch in Zukunft werden wir uns dafür einsetzen, ein lebendiges und vielfältiges Dorfleben in Groß Krams aufzubauen.
                Wir sind davon überzeugt, dass wir durch die Zusammenarbeit und die Berücksichtigung der Stärken und Perspektiven aller unserer Bürger
                eine blühende und integrative Gemeinschaft für alle schaffen können.
                <br /> <br />
                Wir sind dankbar für die Unterstützung und Zusammenarbeit mit unseren Nachbarn und freuen uns darauf,
                auch in den kommenden Jahren zusammenzuarbeiten und etwas zu bewirken. Vielen Dank für Ihr Engagement für unsere gemeinsamen Ziele und Werte!
                <br /><br />
                <ArticleQuote
                    text={"Der Stellingpreis 2022 geht an das Bündnis „Wage Mut“ in Groß Krams für dessen langjähriges soziales," +
                        " zivilgesellschaftliches und demokratieförderndes Wirken in und um die Gemeinde."}
                    author={"SPD Landesfraktion Mecklenburg Vorpommern"}
                />
                <br /><br />
                <ArticleImage
                    src={stellingpreis2Image}
                    alt={"Stellingpreis-Verleihung"}
                    author={"Julia Kleefeld"}
                />
            </ArticleItem>
        </>
    )
}

export default Stellingpreis2022Article;