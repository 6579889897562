import HeroSection from "../feature/core/HeroSection";
import "../App.css";
import "./Home.css";
import Cards from "../feature/cards/Cards";
import EventShowcase from "../feature/events/EventShowcase";
import * as React from "react";


function Home() {
    return (
        <>
            <HeroSection />
            <div className="home-content-wrapper">
                <div className="welcome-container">
                    <div className="welcome-text-container">
                        Willkommen auf der offiziellen Website des Bündnisses "Wage Mut!".
                        <br /><br />
                        Unser Bündnis steht als Symbol für ein demokratisches, friedliches Dorfleben,
                        frei von Feindseligkeit und Hass.
                        Wir glauben an die Kraft einer starken Gemeinschaft und an die Bedeutung der Zusammenarbeit
                        zur Schaffung einer harmonischen Gesellschaft. Wir laden Sie ein, mehr über uns zu erfahren
                        und uns bei unserer Mission zur Förderung von Demokratie und gutem Zusammenleben zu unterstützen.
                    </div>
                </div>
                <EventShowcase />
                <h2><center>Neuigkeiten</center></h2>
                <Cards />
            </div>
        </>
    )
}

export default Home;