import * as React from "react";
import "../App.css";
import "./Literature.css";
import LiteratureItem from "../feature/literature/LiteratureItem";

import bookImage from "../images/literature/book.svg";

function Literature() {
    return (
        <>
            <h1 className="content-title">Literatur</h1>
            <ul className="literature_items">
                <LiteratureItem title="Völkische Landnahme: Alte Sippen, junge Siedler, rechte Ökos"
                                isbn="978-3861539865"
                                text="Für das völkische Milieu ist die Präsenz im ländlichen Raum eine zentrale Strategie, um ihr rechtsextremes Weltbild auf lokaler Ebene zu verankern. Andrea Röpke und Andreas Speit bündeln in diesem Buch ihre jahrelangen Recherchen über die im rechten Spektrum gut vernetzte Szene."
                                pages="264"
                                image={bookImage}
                                src="https://www.amazon.de/V%C3%B6lkische-Landnahme-Sippen-Siedler-rechte/dp/3861539861"/>
                <LiteratureItem title="Sag was!"
                                isbn="978-3-8415-0606-1"
                                text="Rechtspopulismus nimmt immer mehr zu. Doch wie identifiziert man rechtes Gedankengut und vor allem: Wie geht man damit um?"
                                pages="80"
                                image={bookImage}
                                src="https://www.oetinger.de/buch/sag-was/9783841506061"/>
                <LiteratureItem title="Ein deutsches Mädchen"
                                isbn="978-3-608-50375-3"
                                text="Die packende Autobiographie einer Aussteigerin aus der Neonazi-Szene"
                                pages="252"
                                image={bookImage}
                                src="https://www.klett-cotta.de/buch/Tropen-Sachbuch/Ein_deutsches_Maedchen/101138"/>
                <LiteratureItem title="Der gelbe Stern"
                                isbn="978-3867541022"
                                text="Der gelbe Stern erschien im Spätherbst 1960 als eines der ersten Bücher zum Thema."
                                pages="280"
                                image={bookImage}
                                src="https://www.buecher.de/shop/holocaust/der-gelbe-stern/schoenberner-gerhard/products_products/detail/prod_id/39753420/"/>
                <LiteratureItem title="Rechtsextremismus in Ostdeutschland"
                                isbn="978-3-89974-578-8"
                                text="Demokratie und Rechtsextremismus im ländlichen Raum"
                                pages="544"
                                image={bookImage}
                                src="https://www.isbn.de/buch/9783899745788_rechtsextremismus-in-ostdeutschland.htm"/>
                <LiteratureItem title="Irrtum NPD"
                                isbn="978-3873365971"
                                text="Ansichten – Einsichten – Erkenntnisse, Ein Vierteljahrhundert in der Partei"
                                pages="380"
                                image={bookImage}
                                src="https://www.amazon.de/Irrtum-NPD-Einsichten-Erkenntnisse-Vierteljahrhundert/dp/3873365979"/>
            </ul>
        </>
    )
}

export default Literature;