import * as React from "react";
import "./Footer.css";
import {Link} from "react-router-dom";

function Footer() {
    return (
        <>
            <div className="footer-design-splitter">
                <img className="footer-design-splitter-left" alt="left"/>
                <img className="footer-design-splitter-right" alt="right"/>
            </div>
            <div className="footer-container" data-testid="footer">
                {/* TODO Mail abo
               <section className='footer-subscription'>
                    <p className='footer-subscription-heading'>
                        Abonnieren Sie den Newsletter, um aktuelle Neuigkeiten und Veranstaltungshinweise zu erhalten!
                    </p>
                    <small className='footer-subscription-text'>
                        (Sie können den Newsletter jederzeit abbestellen)
                    </small>
                    <div className='input-areas'>
                        <form>
                            <input
                                className='footer-input'
                                name='email'
                                type='email'
                                placeholder='Ihre Mail Adresse'
                            />
                            <Button buttonStyle='btn--outline'>Abonnieren</Button>
                        </form>
                    </div>
                </section>*/}
                <div className="footer-links">
                    <div className="footer-link-wrapper">
                        <div className="footer-link-items">
                            <h2>Navigation</h2>
                            <Link className="footer-link" to="/" data-testid="footer-link">Startseite</Link>
                            <Link className="footer-link" to="/events" data-testid="footer-link">Termine</Link>
                            <Link className="footer-link" to="/articles" data-testid="footer-link">Artikel</Link>
                            <Link className="footer-link" to="/literature" data-testid="footer-link">Literatur</Link>
                            <Link className="footer-link" to="/goals" data-testid="footer-link">Ziele</Link>
                            <Link className="footer-link" to="/community" data-testid="footer-link">Gemeinschaft</Link>
                        </div>
                    </div>
                    <div className='footer-link-wrapper'>
                        <div className='footer-link-items'>
                            <h2>Sonstiges</h2>
                            <Link className="footer-link" to="/contact" data-testid="footer-link">Kontakt</Link>
                            <Link className="footer-link" to="/press" data-testid="footer-link">Presse</Link>
                            <Link className="footer-link" to="/legal/Datenschutzerklaerung" data-testid="footer-link">Datenschutzerklärung</Link>
                            <Link className="footer-link" to="/legal/PrivacySettings" data-testid="footer-link">Cookies und Privatsphäre Einstellungen</Link>
                            <Link className="footer-link" to="/imprint" data-testid="footer-link">Impressum</Link>
                        </div>
                    </div>
                </div>
                <section className="footer-title-section">
                    <div className="footer-title-wrap">
                        <div className="footer-logo">
                            <Link to="/" className="footer-title-text-link">
                                Wage Mut! Bündnis&nbsp;für&nbsp;Demokratie Groß&nbsp;Krams
                            </Link>
                        </div>
                        <small className="website-rights">© 2020 - {new Date().getFullYear()}</small>
                    </div>
                </section>
                {/* TODO don't use node_env! */}
                {process.env.NODE_ENV !== "production" ? <DeploymentInfo /> : ""}
            </div>
        </>
    );
}

function DeploymentInfo() {
    return (
        <>
            <div className="deploy-info-container">
                <div className="deploy-info-online">
                    Frontend:<br/>Staging - 2024-05-14T20:55:28+02:00 - 2afe2a34 - Pipeline 238
                </div>
                <div className="deploy-info-offline">
                    Backend:<br/>Staging - Offline
                </div>
            </div>
        </>
    );
}

export default Footer;