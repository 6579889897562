import {useState} from "react";
import * as React from "react";
import {Target} from "../../../helper/Constants";
import "./Hashtag.css";

interface HashtagProps {
    meta: string;
    facebook?: boolean;
    instagram?: boolean;
    twitter?: boolean;
}

export const Hashtag = (props: HashtagProps) => {

    const [socialSelector, setSocialSelector] = useState(false);

    const showSocialSelector = () => {
        setSocialSelector(true);
    };

    const hideSocialSelector = () => {
        setSocialSelector(false);
    };

    return (
        <div className="hashtag-wrap" onMouseLeave={hideSocialSelector}>
            {socialSelector ? (
                <div className="hashtag-icon-wrap" onClick={hideSocialSelector}>
                    <div className="hashtag-social-hash hashtag-text">#</div>
                    {props.twitter ? (
                        <a href={"https://twitter.com/search?q=%23" + props.meta + "&src=hashtag_click&f=live"} target={Target.BLANK}>
                            <div className="hashtag-social-icon hashtag-twitter" />
                        </a>
                    ) : null }
                    {props.facebook ? (
                        <a href={"https://www.facebook.com/hashtag/" + props.meta} target={Target.BLANK}>
                            <div className="hashtag-social-icon hashtag-facebook" />
                        </a>
                        ) : null }
                    {props.instagram ? (
                        <a href={"https://www.instagram.com/explore/tags/" + props.meta + "/"} target={Target.BLANK}>
                            <div className="hashtag-social-icon hashtag-instagram" />
                        </a>
                    ) : null }
                </div>
            ) : (
                <div className="hashtag-text" onClick={showSocialSelector}>{"#" + props.meta}</div>
            )}
        </div>
    )
};