import * as React from "react";
import "./OptionToggle.css";

interface OptionToggleProps {
    isEnabled: boolean;
    isToggled: boolean;
    onToggle: () => void;
}
export const OptionToggle = (props: OptionToggleProps) => {
    const handleToggle = () => {
        if (props.isEnabled)
            props.onToggle();
    };

    return (
        <label className="option_toggle_label">
            <input type="checkbox" checked={props.isToggled} onChange={handleToggle} />
            <span className="option_toggle_slider"></span>
        </label>
    )
};