import * as React from "react";
import { Link } from "react-router-dom";
import { Target } from "../../helper/Constants";

interface CardItemParams {
    label?: string;
    text: string;
    externalLink?: string;
    path?: string;
    src?: string;
    ytVideo?: string;
}

function CardItem(props: CardItemParams) {
    function getLinkTo() : string {
        if (props.ytVideo) {
            return "/video-article?video=" + props.ytVideo + "&title=" + encodeURIComponent(props.text);
        } else {
            return props.path ? props.path : "/";
        }
    }

    return (
        <>
            { props.externalLink ?
                (
                    <li className="cards_item">
                        <a className="cards_item_link" href={props.externalLink} target={Target.BLANK}>
                            <figure className="cards_image_wrap" data-category={props.label} data-text={props.text}>
                                <img className="cards_item_image"
                                     src={props.src}
                                     alt="Card"/>
                            </figure>
                            <div className="cards_item_info">
                                <h5 className="cards_item_info_text">{props.text}</h5>
                            </div>
                        </a>

                    </li>
                ) : (
                    <li className="cards_item">
                        <Link className="cards_item_link" to={getLinkTo()} target={Target.SELF}>
                            <figure className="cards_image_wrap" data-category={props.label} data-text={props.text}>
                                <img className="cards_item_image"
                                     src={props.ytVideo ? "https://img.youtube.com/vi/" + props.ytVideo + "/mqdefault.jpg" : props.src}
                                     alt="Card"/>
                            </figure>
                            <div className="cards_item_info">
                                <h5 className="cards_item_info_text">{props.text}</h5>
                            </div>
                        </Link>
                    </li>
                )}
        </>
    )
}

export default CardItem;