import "../App.css";
import "./EventDetails.css";
import {GetQueryVariable} from "../helper/QueryTools";
import {useEffect, useState} from "react";
import * as React from "react";
import EventItem from "../feature/events/EventItem";
import EventItemData from "../data/v1/EventItemData";

/**
 * Alias Event (because name collision)
 * @returns {JSX.Element}
 * @constructor
 */
function EventDetails() {
    const [eventError, setEventError] = useState<string | null>(null);
    const [eventIsLoaded, setEventIsLoaded] = useState(false);
    const [eventItems, setEventItems] = useState<EventItemData[] | []>([]);

    useEffect(() => {
        fetch("/api/v1/events/event/" + GetQueryVariable("id") + "/")
            .then(res => res.json())
            .then(
                (result) => {
                    setEventIsLoaded(true);
                    setEventItems(result);
                },
                (error) => {
                    setEventIsLoaded(true);
                    setEventError(error);
                }
            )
    }, [])
    return (
        <>
            {eventIsLoaded ? (!eventError ? eventItems.map(e => (
                <EventItem id={e.id} title={e.title} text={e.text} date={e.start_date} enddate={e.end_date} location={e.location} image={e.image} />
            )):<center><p>{"Event Informationen nicht gefunden!"}</p></center>) : <center><p>Event wird geladen...</p></center>}
        </>
    )
}

export default EventDetails;