import * as React from "react";
import "../../App.css";

import RawVideoArticleItem from "../../feature/article/RawVideoArticleItem";

function SchwerinAfDWahlauftaktVideoArticle() {
    return (
        <>
            <RawVideoArticleItem
                title={"Schwerin AfD Wahlauftakt"}
                video={"schwerin_afd_wahlauftakt.mp4"}
                author={"Wage Mut Bündnis"}
                date={"Freitag, 12. August 2021"}
            />
        </>
    )
}

export default SchwerinAfDWahlauftaktVideoArticle;