import * as React from "react";
import "./PrivacyBanner.css";
import {Button} from "../core/controls/Button";
import {usePrivacyBoxStore, usePrivacyStore} from "../../stores/PrivacyStores";
import {Link} from "react-router-dom";

function PrivacyBanner() {
    const { allowFunctionalCookies, disallowAll } = usePrivacyStore();
    const { activateFunctionalCookies, deactivateAll} = usePrivacyBoxStore();
    return (
        <div className="privacy-banner-container">
            <div className="privacy-banner-text-container">
                Unsere Website verwendet Cookies, um Ihr Surferlebnis zu verbessern und Inhalte und Anzeigen zu personalisieren.
                Durch die weitere Nutzung unserer Seite erklären Sie sich mit unserer Datenschutzerklärung einverstanden.
                <div className="privacy-banner-link-container">
                    <Link to={"/legal/Datenschutzerklaerung"}>Datenschutzerklärung</Link>
                    <Link to={"/legal/PrivacySettings"}>Cookies und Privatsphäre Einstellungen</Link>
                </div>
            </div>
           <div className="privacy-banner-button-container">
               <Button buttonStyle="btn--primary" buttonSize="btn--large" onClick={() => { allowFunctionalCookies(); activateFunctionalCookies(); }} isEnabled={true} type={undefined} >
                   Funktionale Cookies akzeptieren
               </Button>
               <Button buttonStyle="btn--outline" buttonSize="btn--large" onClick={() => { disallowAll(); deactivateAll(); }} isEnabled={true} type={undefined} >
                   Alles ablehnen
               </Button>
           </div>
        </div>
    )
}

export default PrivacyBanner;