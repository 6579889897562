import * as React from "react";

import "./ErrorArea.css";

interface ErrorAreaParams {
    text: string;
    code: number;
}

function ErrorArea(props: ErrorAreaParams) {
    return (
        <div className="error_area">
            <div className="error_text_container content-box">
                <h1>{"Error " + props.code}</h1>
                <p>{props.text}</p>
            </div>
        </div>
    )
}

export default ErrorArea;