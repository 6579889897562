import * as React from "react";
import "./HeroSection.css";
import "../../App.css";
import {Hashtag} from "../social/hashtag/Hashtag";

function HeroSection() {
    return (
        <>
            <div className="hero-container" data-testid="herosection">
                <div className="hero-text-container">
                    <h2>WAGE MUT!</h2>
                    <h1>BÜNDNIS&nbsp;FÜR<br />DEMOKRATIE</h1>
                </div>
                <div className="hero-hashtags">
                    <Hashtag meta={"gruppebeherzt"} instagram={true} />
                    <Hashtag meta={"unteilbarMV"} instagram={true} facebook={true} twitter={true}/>
                </div>
                <div className="hero-extended-title">„Wagemutig Demokratie leben“</div>
            </div>
        </>
    )
}

export default HeroSection;