import "./Navbar.css";
import {useState} from "react";
import * as React from "react";
import { Link } from "react-router-dom";

import logo from "../../images/ui/logo_mini.png";

function Navbar() {
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    return(
        <>
            <nav className="navbar" data-testid="navbar">
                <div className="navbar-container">
                    <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                        <img id="navbar-logo-icon" src={logo} alt="logo" />
                    </Link>
                    <div className="menu-icon" onClick={handleClick}>
                        <img className={click ? "menu-back" : "menu-burger"} alt="nav" />
                    </div>
                    <ul className={click ? "nav-menu active" : "nav-menu"}>
                        <li className="nav-item">
                            <Link to="/" className="nav-links" onClick={closeMobileMenu} data-testid="nav-link">
                                Startseite
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/events" className="nav-links" onClick={closeMobileMenu} data-testid="nav-link">
                                Termine
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/articles" className="nav-links" onClick={closeMobileMenu} data-testid="nav-link">
                                Artikel
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/literature" className="nav-links" onClick={closeMobileMenu} data-testid="nav-link">
                                Literatur
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/goals" className="nav-links" onClick={closeMobileMenu} data-testid="nav-link">
                                Ziele
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/community" className="nav-links" onClick={closeMobileMenu} data-testid="nav-link">
                                Gemeinschaft
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/press" className="nav-links" onClick={closeMobileMenu} data-testid="nav-link">
                                Presse
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/contact" className="nav-links" onClick={closeMobileMenu} data-testid="nav-link">
                                Kontakt
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    )
}

export default Navbar;