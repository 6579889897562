import {useEffect, useState} from "react";
import * as React from "react";
import EventListItem from "./EventListItem";
import {Link} from "react-router-dom";
import "./EventShowcase.css";
import EventItemData from "../../data/v1/EventItemData";
import StatsItemData from "../../data/v1/StatsItemData";

function EventShowcase() {
    const [upcomingError, setUpcomingError] = useState(null);
    const [upcomingIsLoaded, setUpcomingIsLoaded] = useState(false);
    const [upcomingItems, setUpcomingItems] = useState<EventItemData[] | []>([]);
    const [statsError, setStatsError] = useState(null);
    const [statsIsLoaded, setStatsIsLoaded] = useState(false);
    const [statsItem, setStatsItem] = useState<StatsItemData | null>(null);

    useEffect(() => {
        fetch("/api/v1/events/showcase")
            .then(res => res.json())
            .then(
                (result) => {
                    setUpcomingIsLoaded(true);
                    setUpcomingItems(result);
                },
                (error) => {
                    setUpcomingIsLoaded(true);
                    setUpcomingError(error);
                }
            )
        fetch("/api/v1/events/stats")
            .then(res => res.json())
            .then(
                (result) => {
                    setStatsIsLoaded(true);
                    setStatsItem(result);
                },
                (error) => {
                    setStatsIsLoaded(true);
                    setStatsError(error);
                }
            )
    }, [])

    if (upcomingIsLoaded && !upcomingError && upcomingItems.length > 0) {
        return (
            <>
                {upcomingItems.map((e: EventItemData) => (
                    <EventListItem id={e.id} title={e.title} text={e.text} date={e.start_date} enddate={e.end_date} location={e.location} image={e.image} />
                ))}
                { statsIsLoaded && !statsError ? statsItem != null && statsItem.normal > 0 ? <Link className="other-events" to={"/events"}><center><p>{"und " + statsItem.normal + " weitere Events ..."}</p></center></Link> : null : null }
            </>
        )
    }

   return null
}

export default EventShowcase;