import * as React from "react";
import "../../App.css";
import "./EventItem.css";
import {DateTime} from "luxon";
import {GetDateRangeStr, GetTimeLeftStr} from "../../helper/DateTimeTools";

export interface EventItemParams {
    id: number;
    title: string;
    text?: string;
    location: string;
    date: string;
    enddate?: string;
    image?: string;
}

function EventItem(props: EventItemParams) {
    const eventStart: DateTime = getEventStart(props);
    const eventEnd: DateTime | null = getEventEnd(props);
    const daysLeft: number = getEventDaysLeft(eventStart);

    return (
        <>
            <div className="event-wrap content-box" >
                <div className="event-title-wrap">
                    <h1 className="event-title">{props.title}</h1>
                </div>
                <div className="event-date-wrap">
                    <div className="event-date">{GetDateRangeStr(eventStart, eventEnd)}</div>
                    <div className="event-time-left">{GetTimeLeftStr(daysLeft)}</div>
                </div>
                <div className="event-location">{props.location}</div>
                <div className="event-text">
                    {props.text}
                </div>
            </div>
        </>
    )
}

export function getEventStart(props: EventItemParams) : DateTime {
    return DateTime.fromISO(props.date).setLocale('de');
}

export function getEventEnd(props: EventItemParams) : DateTime | null {
    return props.enddate && props.date !== props.enddate ? DateTime.fromISO(props.enddate).setLocale('de') : null;
}

export function getEventDaysLeft(eventStart: DateTime) : number {
    return eventStart.startOf("day").diff(DateTime.now().startOf("day").setLocale("de"), "days").as("days");
}

export default EventItem;