import * as React from "react";
import "./ArticleQuote.css";

interface ArticleQuoteParams {
    text: string;
    author: string;
}

function ArticleQuote(props: ArticleQuoteParams) {
    return (
        <blockquote className="article-quote">
            { props.text }
            <cite>{ "- " + props.author }</cite>
        </blockquote>
    )
}

export default ArticleQuote;