import {useEffect, useState} from "react";
import * as React from "react";
import "../App.css";
import "./Events.css";
import EventListItem from "../feature/events/EventListItem";
import EventItemData from "../data/v1/EventItemData";

function Events() {
    const [upcomingError, setUpcomingError] = useState(null);
    const [upcomingIsLoaded, setUpcomingIsLoaded] = useState(false);
    const [upcomingItems, setUpcomingItems] = useState<EventItemData[] | []>([]);
    const [finishedError, setFinishedError] = useState(null);
    const [finishedIsLoaded, setFinishedIsLoaded] = useState(false);
    const [finishedItems, setFinishedItems] = useState<EventItemData[] | []>([]);

    useEffect(() => {
        fetch("/api/v1/events/upcoming")
            .then(res => res.json())
            .then(
                (result) => {
                    setUpcomingIsLoaded(true);
                    setUpcomingItems(result);
                },
                (error) => {
                    setUpcomingIsLoaded(true);
                    setUpcomingError(error);
                }
            )
        fetch("/api/v1/events/finished")
            .then(res => res.json())
            .then(
                (result) => {
                    setFinishedIsLoaded(true);
                    setFinishedItems(result);
                },
                (error) => {
                    setFinishedIsLoaded(true);
                    setFinishedError(error);
                }
            )
    }, [])

    return (
        <>
            <h1 className="content-title">Termine</h1>
            {upcomingIsLoaded ? (!upcomingError ? upcomingItems.map(e => (
                <EventListItem id={e.id} title={e.title} date={e.start_date} enddate={e.end_date} location={e.location} />
            )):<center><p>{"Keine Events gefunden!"}</p></center>) : <center><p>Events werden geladen...</p></center>}
            <h2><center>Beendet</center></h2>
            {finishedIsLoaded ? (!finishedError ? finishedItems.map(e => (
                <EventListItem id={e.id} title={e.title} date={e.start_date} enddate={e.end_date} location={e.location} />
            )):<center><p>{"Keine Events gefunden!"}</p></center>) : <center><p>Events werden geladen...</p></center>}
        </>
    )
}

export default Events;