import {Tweet} from "react-twitter-widgets"
import "./TweetItem.css";
import {usePrivacyStore} from "../../../stores/PrivacyStores";
import {PrivacyChoiceBox} from "../../privacy/PrivacyChoiceBox";
import * as React from "react";

interface TweetItemParams {
    id: string;
}

function TweetItem(props: TweetItemParams) {
    const { functionalCookies, socialTwitter } = usePrivacyStore();
    return (
        <>
            <div className="tweet-wrap">
                {functionalCookies && socialTwitter ? (
                    <Tweet tweetId={props.id} options={{lang: "de"}}/>
                ) : (
                    <div className="tweet-show-external-content-wrap">
                        <PrivacyChoiceBox isRequired={true} showTwitter={true} showYoutube={false} />
                    </div>
                )}
            </div>
        </>
    )
}

export default TweetItem;