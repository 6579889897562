import * as React from "react";
import CardItem from "./CardItem";
import "./Cards.css";

import welcomeGkImage from "../../images/ui/groß_krams_small.jpg";
import stellingpreisImage from "../../images/article/stellingpreis2022/stellingpreis_1.jpg";
import cover1 from "../../images/cover_0001.png";
import cover2 from "../../images/cover_0002.png";
import schwerinAfDWahlauftaktImage from "../../images/schwerin_afd_wahlauftakt.jpg";
import faeserNancyImage from "../../images/Faeser_Nancy_feb19.jpg";
import bfdtImage from "../../images/article/bfdt.jpg";
import karyPreis from "../../images/article/erich-kary-preis-2024.png";

function Cards() {
    return (
        <div className="cards">
            <div className="cards_container">
                <div className="cards_wrapper">
                    <ul className="cards_items">
                        <CardItem src={karyPreis} label="Neuigkeit" text={"„Wage Mut“ gewinnt Erich Kary Preis 2024"} path="/articles/static/ErichKaryPreis2024"/>
                        <CardItem src={bfdtImage} label="Neuigkeit" text={"Preisträger \"Aktiv für Demokratie und Toleranz\" 2022"} path="/articles/static/AktivFuerDemokratieUndToleranz2022"/>
                    </ul>
                    <ul className="cards_items">
                        <CardItem src={stellingpreisImage} label="Neuigkeit" text="„Wage Mut“ gewinnt Stellingpreis 2022" path="/articles/static/Stellingpreis2022"/>
                        <CardItem ytVideo={"Zyt1XEEdM90"} label="YouTube Video - Podcast" text="Gemeinsam Stark. Landesprogramm unterwegs (F.9)"/>
                        <CardItem src={faeserNancyImage} label="Neuigkeit" text="Bundesinnenministerin Nancy Faeser" path="/articles/static/BundesinnenministeriumNancyFaeser"/>
                    </ul>
                    <ul className="cards_items">
                        <CardItem src={cover1} label="Externer Inhalt" text="Völkische Siedler unterwandern ländlichen Raum in Meck-Vorp" externalLink="https://katapult-mv.de/artikel/voelkische-siedler-unterwandern-laendlichen-raum-in-meck-vorp"/>
                        <CardItem src={cover2} label="Externer Inhalt" text="Die Landnahme - Rechte Gemeindevertreter in Groß Krams" externalLink="https://taz.de/Rechte-Gemeindevertreter-in-Gross-Krams/!5640877/"/>
                        <CardItem src={schwerinAfDWahlauftaktImage} label="Video" text="Schwerin AfD Wahlauftakt" path="/articles/static/video/SchwerinAfDWahlauftakt"/>
                    </ul>
                    <ul className="cards_items">
                        <CardItem ytVideo={"pgNUkxYPk1E"} label="YouTube Video - Reaction" text="KEINE HUGOS FÜR NAZIS! Mein Nachbar ist Nazi - Was tun? | Doku | NDR [React]"/>
                        <CardItem ytVideo={"UxX4Fgr91eA"} label="YouTube Video - Reaction" text="Reaction Teil 1 - Mein Nachbar der Nazi | Was tun? - NDR"/>
                        <CardItem ytVideo={"g5I2VuTl-Y4"} label="YouTube Video - Reaction" text="Reaction Teil 2 - Mein Nachbar der Nazi | Was tun? - NDR"/>
                    </ul>
                    <ul className="cards_items">
                        <CardItem ytVideo={"Jb-51PzkhUg"} label="YouTube Video - Event" text="Open-Air- und Traktorenkino Groß Krams ..."/>
                        <CardItem ytVideo={"K4BH7cL8KTY"} label="YouTube Video - Doku" text="Mein Nachbar ist Nazi - Was tun? | Doku | NDR | 45 Min"/>
                        <CardItem src={welcomeGkImage} label="Neuigkeit" text="Unsere neue Homepage" path="/articles/static/HerzlichWillkommen"/>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Cards;